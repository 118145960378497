import React, { createContext, useReducer } from 'react';

const initialState = {
    dialogBox: null,
};

const AuthContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DIALOG":
      return {
        ...state,
        dialogBox: action.list,
      }
    default:
      return state;
  }
};

const ContextState = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export {
  AuthContext,
  ContextState
};
