import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import 'typeface-roboto';
import "./App.scss";

import { MainContextProvider } from "./_store/main.store.js";
import { AuthContextProvider } from "./_store/auth.store.js";
import { ContextState } from "./_reducer/context.js";
import ModuleLoader from "./moduleLoader";
export const myConst = 123;

const App = () => {
    return (
        <div className="App">
            <div className="main_container">
                <BrowserRouter>
                    <MainContextProvider>
                        <AuthContextProvider>
                            <ContextState>
                                <Route
                                    path="/:module?/:id?/:token?"
                                    component={ModuleLoader}
                                />
                            </ContextState>
                        </AuthContextProvider>
                    </MainContextProvider>
                </BrowserRouter>
            </div>
        </div>
    );
}

export default App;
