import React from "react";
import SpinLoader from 'react-loader-spinner';
import "./Loader.scss";

const Loader = (props) => {
    return (
        <div className="Loader">
            <div className="frame">
                <SpinLoader type={props.type} color="gray" height={60} width={60} />
            </div>
        </div>
    );
};

Loader.defaultProps = {
    type: "Oval"
};

export { Loader };
