import React, { createContext, useState } from "react";

export const MainContext = createContext({});

export const MainContextProvider = ({ children }) => {
    const ln = localStorage.getItem("lang") || 'mng';
    const [popUpBody, setPopUp] = useState(null);
    const [language, setLanguage] = useState(ln);
    const [pages, setPages] = useState(null);

    const provider = {
        language,
        popUpBody,
        pages,
        setLanguage: (lang) => {
            localStorage.setItem("lang", lang);
            setLanguage(lang);
        },
        setPopUp: (body) => {
            setPopUp(body)
        },
        setPages: (pages) => {
            setPages(pages);
        }
    };

    return (
        <MainContext.Provider value={provider}>
            {children}
        </MainContext.Provider>
    );
};
